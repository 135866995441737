export const TAX = {
  lv: 13,
  wpg: 5,
};

export const STUBS_DATA = {
  lv: [
    {
      id: 1,
      name: 'GOLD',
      saveNameAs: 'GOLD OP 19',
      newPrice: {
        total: '99.95',
        enrolment: '199.95',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '96.95',
        enrolment: '299.95',
        roadMapSession: '49.95',
      },
      commitment: '12-Month Commitment',
      emphasis: '',
      additionalInfo: [
        'Unlimited Studio Fitness Classes (200+ weekly)',
        'Unlimited LF3 On-Demand Workouts (Online Library)',
        '12-Month Commitment',
        'Towel Service',
        '2-hour Complimentary Parking',
        '$199.95 Initiation Fee',
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 2,
      name: 'PLATINUM',
      saveNameAs: 'PLATINUM OP 19',
      newPrice: {
        total: '102.95',
        enrolment: '199.95',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '96.95',
        enrolment: '299.95',
        roadMapSession: '49.95',
      },
      commitment: '12-Month Commitment',
      emphasis: '',
      additionalInfo: [
        'Multi-club Access Privileges: Access To All Altea Active Wellness & Social Clubs Across Canada',
        'Unlimited Studio Fitness Classes (200+ weekly)',
        'Unlimited LF3 On-Demand Workouts (Online Library)',
        '<b>2 Complimentary One Day Guest Passes</b>',
        '12 Weeks Of Freeze Privileges Per 12-Month Period*',
        '12-Month Commitment (See FLEX option below)',
        'Towel Service',
        '2-hour Complimentary Parking',
        '$199.95 Initiation Fee',
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 3,
      name: 'PLATINUM FLEX',
      saveNameAs: 'PLATINUM FLEX OP 19',
      newPrice: {
        total: '127.95',
        enrolment: '249.95',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '106.95',
        enrolment: '399.95',
        roadMapSession: '49.95',
      },
      commitment: '<b>No Commitment. Membership may be cancelled anytime with 30-days notice.</b>',
      emphasis: '',
      additionalInfo: [
        '<b>ALL PLATINUM MEMBER BENEFITS WITH NO COMMITMENT*</b>',
        '$249.95 Initiation Fee  ($399.95 Regular)',
        '* Memberships are month to month and can be cancelled at any time with 30-days notice.',
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 4,
      name: 'DIAMOND',
      saveNameAs: 'DIAMOND OP 19',
      newPrice: {
        total: '134.95',
        enrolment: '0',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '184.95',
        enrolment: '299',
        roadMapSession: '49.95',
      },
      commitment: '12-Month Commitment',
      emphasis: '',
      additionalInfo: [
        'Multi-club Access Privileges: Access To All Altea Active Wellness & Social Clubs Across Canada',
        '<b>12-Month Commitment</b>',
        'Unlimited LF3 Small Group Training Workouts (In-Studio)',
        'LF3 Rx Customized Training Plan',
        'Monthly Body Composition Scans',
        'LF3 Coach Accountability & Support',
        'Unlimited Studio Fitness Classes (200+ weekly)',
        'Unlimited LF3 Live (Online / Interactive) + On-Demand Workouts (online library)',
        '<b>4 Complimentary One Day Guest Passes</b>',
        '12 Weeks Of Freeze Privileges Per 12-Month Period*',
        'Complimentary Topgolf, Somadome or Bowling (6 total bookings per month)',
        'Towel Service',
        '2-hours Complimentary Parking',
        '$0 Initiation Fee'
      ],
      freeBiWeeklies: '0',
    },
    {
      id: 5,
      name: 'DIAMOND FLEX',
      saveNameAs: 'DIAMOND FLEX OP 19',
      newPrice: {
        total: '159.95',
        enrolment: '249.95',
        roadMapSession: 0,
      },
      oldPrice: {
        total: '184.95',
        enrolment: '299',
        roadMapSession: '49.95',
      },
      commitment: '3-Month Commitment',
      emphasis: '',
      additionalInfo: [
        'Multi-club Access Privileges: Access To All Altea Active Wellness & Social Clubs Across Canada',
        '<b>3-Month Commitment</b>',
        'Unlimited LF3 Small Group Training Workouts (In-Studio)',
        'LF3 Rx Customized Training Plan',
        'Monthly Body Composition Scans',
        'LF3 Coach Accountability & Support',
        'Unlimited Studio Fitness Classes (200+ weekly)',
        'Unlimited LF3 Live (Online / Interactive) + On-Demand Workouts (online library)',
        '<b>4 Complimentary One Day Guest Passes</b>',
        '12 Weeks Of Freeze Privileges Per 12-Month Period*',
        'Complimentary Topgolf, Somadome or Bowling (6 total bookings per month)',
        'Towel Service',
        '2-hours Complimentary Parking',
        '$249.95 Initiation Fee'
      ],
      freeBiWeeklies: '0',
    },
  ],
  wpg: [
    {
      id: 6,
      name: 'GOLD',
      saveNameAs: 'GOLD',
      newPrice: {
        total: '38.95',
        enrolment: '69.95',
        roadMapSession: '24.95',
      },
      oldPrice: {
        total: '44.95',
        enrolment: '199',
        roadMapSession: '49.95',
      },
      commitment: '12 month commitment',
      emphasis: '',
      additionalInfo: [
        '12-month commitment',
        'Unlimited Studio Fitness Classes (190+ weekly), including Yoga & Aquatics',
        '$69.95 Initiation Fee',
      ],
      freeBiWeeklies: 0,
    },
    {
      id: 7,
      name: 'PLATINUM',
      saveNameAs: 'PLATINUM',
      newPrice: {
        total: '45.95',
        enrolment: '39.95',
        roadMapSession: '24.95',
      },
      oldPrice: {
        total: '54.95',
        enrolment: '199',
        roadMapSession: '49.95',
      },
      commitment: '12 month commitment',
      emphasis: '',
      additionalInfo: [
        '12-month commitment',
        'Freeze Privileges - up to 6 Biweekly cycles maximum per calendar year',
        'Unlimited Studio Fitness Classes (190+ weekly), including Yoga & Aquatics',
        'Towel Service',
        '$39.95 Initiation Fee',
      ],
      freeBiWeeklies: 0,
    },
    {
      id: 8,
      name: 'GOLD FLEX',
      saveNameAs: 'GOLD FLEX',
      newPrice: {
        total: '48.95',
        enrolment: '109.95',
        roadMapSession: '24.95',
      },
      oldPrice: {
        total: '67.95',
        enrolment: '249',
        roadMapSession: '49.95',
      },
      commitment: '<b>No commitment</b>',
      emphasis: '',
      additionalInfo: [
        'Unlimited Studio Fitness Classes (190+ weekly), including Yoga & Aquatics',
        '$109.95 Initiation Fee',
      ],
      freeBiWeeklies: 0,
    },
  ],
};

export const SALESPERSON_OPTIONS = {
  lv: [
    {value: 'Eric Baumer', label: 'Eric Baumer'},
    {value: 'Hannah Paola', label: 'Hannah Paola'},
  ],
  wpg: [
    {value: 'Grayson Collins', label: 'Grayson Collins'},
    {value: 'Izzy Funk', label: 'Izzy Funk'},
    {value: 'Norah Fan', label: 'Norah Fan'},
    {value: 'Paul Geli', label: 'Paul Geli'},
  ],
};
