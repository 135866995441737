import {makeAutoObservable} from 'mobx';
import {differenceInDays} from 'date-fns';
import {STUBS_DATA, TAX} from '../../Constants';
import {format as dateFormat} from '../../Helpers/date';
import {storeGeneral} from '../General';

// Last friday of the year, Dec 29 2023
const START = new Date(2024, 11, 27, 23, 59, 59);

// console.log('START constant: ' + START);

export class StoreMembership {
  items = [];
  activeID;
  complete = false;
  tax = null;

  constructor() {
    makeAutoObservable(this);
  }

  get(city) {
    if (city == null) {
      city = 'wpg';
    }
    this.tax = TAX[city];

    let outfit = new URLSearchParams(window.location.search).get('outfit');

    if (!outfit) {
      // We're doing it as per normal,
      // showing the plan page and pulling plan
      // info from Constants.js
      this.items = STUBS_DATA[city];
      if (
        !this.activeID ||
        !this.items.find((item) => item.id === this.activeID)
      ) {
        if (city === 'wpg') {
          this.setActiveID(this.items.find((item) => item.name === 'PLATINUM').id);
        } else {
          this.setActiveID(this.items.find((item) => item.name === 'DIAMOND').id);
        }
      }
    } else {
      storeGeneral.navigation.next()
    }
  }

  get activeItem() {
    let outfit = new URLSearchParams(window.location.search).get('outfit');

    if (outfit) {
      let planInfo = atob(outfit);
      let urlParams = new URLSearchParams(planInfo);

      //planName=DIAMOND&groupName=DIAMOND OP 12&enrolment=0&biWeekly=154.95&roadMapSession=0&committment=12 month committment&freeBiWeeklies=0

      let items = []
      items.id = 1;
      items.name = urlParams.get('planName');
      items.saveNameAs = urlParams.get('groupName');
      items.newPrice = []
      items.newPrice.total = urlParams.get('biWeekly');;
      items.newPrice.enrolment = urlParams.get('enrolment');;
      items.newPrice.roadMapSession = urlParams.get('roadMapSession');;
      items.commitment = urlParams.get('committment');;
      items.freeBiWeeklies = urlParams.get('freeBiWeeklies');;
      
      return items;
    } else {
      return this.items.find((i) => this.activeID === i.id);
    }
  }

  get daysUntilNextPayment() {
    return Math.abs((differenceInDays(START, new Date()) % 14));
  }

  get nextDuesDate() {
    let date = new Date();
    let countFreebies = Math.max(0, (this.activeItem.freeBiWeeklies - 1) * 14);
    // console.log(countFreebies);
    date.setDate(date.getDate() + this.daysUntilNextPayment + countFreebies);
    return dateFormat(date);
  }

  get totalTax() {
    return `${(((Number(this.firstBiWeeklyDues) + Number(this.activeItem.newPrice.roadMapSession) + Number(this.activeItem.newPrice.enrolment))/100)*this.tax).toFixed(2)}`;
  }

  get firstBiWeeklyDues() {
    if (!this.activeItem) {
      return null;
    }

    if (this.activeItem.freeBiWeeklies > 0) {
      return '0';
    } else {
      // console.log('Days until next payment: ' + this.daysUntilNextPayment);

      let total = ((this.activeItem.newPrice.total / 14) * this.daysUntilNextPayment).toFixed(2);
      if (total === 0.00) {
        return this.activeItem.newPrice.total;
      } else {
        return total;
      }
    }
  }

  get totalDueToday() {
    return `${(
      Number(this.firstBiWeeklyDues) + 
      Number(this.activeItem.newPrice.roadMapSession) +
      Number(this.activeItem.newPrice.enrolment) +
      Number(this.totalTax)).toFixed(2)
    }`
  }

  get startDate(){
    return dateFormat(new Date());
  }

  setActiveID(id) {
    this.complete = true;
    this.activeID = id;
  }
}
